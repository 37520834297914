import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BaseApiComponent} from '../../api/base.api.component';
import {CourseDetails} from '../../api/models/course-details';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.css']
})
export class CourseDetailsComponent extends BaseApiComponent implements OnInit {

  courseSlug: string | null = '';
  courseDetails: CourseDetails;

  constructor(private router: ActivatedRoute, private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.router.paramMap.subscribe(params => {
      this.courseSlug = params.get('slug');
      this.getCourseDetails();
    });
  }

  getCourseDetails(): void {
    this.apiHttpService
      .get(this.apiEndpointService.getCourseDetails(this.courseSlug))
      .subscribe((result) => {
        this.courseDetails = result;
      });
  }
}
