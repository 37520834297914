<div class="site-wrap">

  <app-header></app-header>

  <app-course-intro [course]="courseDetails?.course"></app-course-intro>

  <app-course-desc [content]="courseDetails?.course?.content"></app-course-desc>

  <app-course-content [lessons]="courseDetails?.lessons"></app-course-content>

  <app-course-suggestions [suggestions]="courseDetails?.recommended"></app-course-suggestions>

  <app-course-reviews [feedbacks]="courseDetails?.feedbacks"></app-course-reviews>

  <app-footer></app-footer>

</div>
