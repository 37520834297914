import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './ui/home/home.component';
import {HeaderComponent} from './commons/header/header.component';
import {IntroComponent} from './ui/home/intro/intro.component';
import {CoursesComponent} from './ui/home/courses/courses.component';
import {AboutmeComponent} from './ui/home/aboutme/aboutme.component';
import {ContactComponent} from './ui/home/contact/contact.component';
import {FooterComponent} from './commons/footer/footer.component';
import {SubscriptionformComponent} from './ui/home/intro/subscriptionform/subscriptionform.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ApiHttpService} from './api/core/services/api-http.service';
import {ApiEndpointsService} from './api/core/services/api-endpoint.service';
import {Constants} from './api/config/constants';
import {CourseCardComponent} from './ui/home/courses/course-card/course-card.component';
import {CourseDetailsComponent} from './ui/course-details/course-details.component';
import {CourseIntroComponent} from './ui/course-details/course-intro/course-intro.component';
import {CourseDescComponent} from './ui/course-details/course-desc/course-desc.component';
import {CourseContentComponent} from './ui/course-details/course-content/course-content.component';
import {CourseSuggestionsComponent} from './ui/course-details/course-suggestions/course-suggestions.component';
import {CourseReviewsComponent} from './ui/course-details/course-reviews/course-reviews.component';
import {DateAgoPipe} from './utils/pipes/date-ago.pipe';
import {ReviewCardComponent} from './ui/course-details/course-reviews/review-card/review-card.component';
import {StartCourseComponent} from './ui/start-course/start-course.component';
import {StartCourseIntroComponent} from './ui/start-course/start-course-intro/start-course-intro.component';
import {VideoPlayerComponent} from './ui/start-course/video-player/video-player.component';
import {IndexComponent} from './ui/start-course/index/index.component';
import {SafePipe} from './utils/pipes/safe.pipe';
import {AuthComponent} from './ui/auth/auth/auth.component';
import {AngularFireModule} from "@angular/fire";
import {environment} from "../environments/environment";
import {AngularFireAuth} from "@angular/fire/auth";
import { AllCoursesComponent } from './ui/all-courses/all-courses.component';
import { AboutComponent } from './ui/about/about.component';
import { DownloadComponent } from './ui/download/download.component';
import { PrivacyPolicyComponent } from './ui/privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    IntroComponent,
    CoursesComponent,
    AboutmeComponent,
    ContactComponent,
    FooterComponent,
    SubscriptionformComponent,
    CourseCardComponent,
    CourseDetailsComponent,
    CourseIntroComponent,
    CourseDescComponent,
    CourseContentComponent,
    CourseSuggestionsComponent,
    CourseReviewsComponent,
    DateAgoPipe,
    ReviewCardComponent,
    StartCourseComponent,
    StartCourseIntroComponent,
    VideoPlayerComponent,
    IndexComponent,
    SafePipe,
    AuthComponent,
    AllCoursesComponent,
    AboutComponent,
    DownloadComponent,
    PrivacyPolicyComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        FormsModule,
    ],
  providers: [
    Constants,
    ApiHttpService,
    ApiEndpointsService,
    AngularFireAuth
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
