import {Injectable, Injector} from '@angular/core';
import {ApiHttpService} from './core/services/api-http.service';
import {ApiEndpointsService} from './core/services/api-endpoint.service';

@Injectable()
export abstract class BaseApiComponent {

  protected apiHttpService: ApiHttpService;
  protected apiEndpointService: ApiEndpointsService;

  protected constructor(private inject: Injector) {
    this.apiHttpService = this.inject.get(ApiHttpService);
    this.apiEndpointService = this.inject.get(ApiEndpointsService);
  }
}

