<div class="site-mobile-menu site-navbar-target">
  <div class="site-mobile-menu-header">
    <div class="site-mobile-menu-close mt-3">
      <span class="icon-close2 js-menu-toggle"></span>
    </div>
  </div>
  <div class="site-mobile-menu-body"></div>
</div>


<header id="header" class="site-navbar py-4 js-sticky-header site-navbar-target" role="banner">

  <div class="container-fluid">
    <div class="d-flex align-items-center">
      <div class="site-logo mr-auto w-25"><a href="index.html">Simplified Coding</a></div>

      <div class="mx-auto text-center">
        <nav class="site-navigation position-relative text-right" role="navigation">
          <ul class="site-menu main-menu js-clone-nav mx-auto d-none d-lg-block  m-0 p-0">
            <li><a href="/" class="nav-link">Home</a></li>
            <li><a href="/all-courses" class="nav-link">Courses</a></li>
            <li><a href="https://www.simplifiedcoding.net" target="_blank" class="nav-link">Blog</a></li>
            <li><a href="/about" class="nav-link">About</a></li>
          </ul>
        </nav>
      </div>

      <div class="ml-auto w-25">
        <nav class="site-navigation position-relative text-right" role="navigation">
          <ul class="site-menu main-menu site-menu-dark js-clone-nav mr-auto d-none d-lg-block m-0 p-0">
            <div *ngIf="authService.currentUser == null; then login else loggedIn"></div>
            <ng-template #login>
              <li class="cta">
                <a (click)="authService.GoogleAuth()" class="nav-link"><span>Login/Signup</span></a>
              </li>
            </ng-template>
            <ng-template #loggedIn>
              <li class="nav-item dropdown cta">
                <a class="nav-link dropdown-toggle btn btn-primary user-dropdown" href="#" id="navbarDropdown"
                   role="button" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                  {{ authService.currentUser?.displayName }}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <button class="dropdown-item" (click)="authService.Logout()"><i class="fa fa-sign-out"></i> Logout
                  </button>
                </div>
              </li>
            </ng-template>
          </ul>
        </nav>
        <a href="#" class="d-inline-block d-lg-none site-menu-toggle js-menu-toggle text-black float-right"><span
          class="icon-menu h3"></span></a>
      </div>
    </div>
  </div>

</header>
