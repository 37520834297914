<div class="container-fluid" style="background-color: #3e3e3e">
  <div class="container" style="padding-top: 64px; padding-bottom: 24px;">
    <div class="row">
      <div class="col-md-12">
        <div class="text-center">
        </div>
      </div>
    </div>
  </div>
</div>
<br/>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="text-center">
        <h1>{{courseTitle}}</h1>
      </div>
    </div>
  </div>
</div>
<hr/>
