import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {BaseApiComponent} from '../../../../api/base.api.component';

@Component({
  selector: 'app-subscriptionform',
  templateUrl: './subscriptionform.component.html',
  styleUrls: ['./subscriptionform.component.css']
})
export class SubscriptionformComponent extends BaseApiComponent implements OnInit {

  subscriptionForm = this.formBuilder.group({
    email: ['', Validators.email]
  });

  subscribed = false;

  constructor(
    private formBuilder: FormBuilder,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.apiHttpService.post(
      this.apiEndpointService.getSubscribeToNewsletterEndpoint(), {email: this.subscriptionForm.value.email}
    ).subscribe(() => {
      this.subscribed = true;
      console.log('Subscribed');
    });
  }
}
