import {Component, Input, OnInit} from '@angular/core';
import {Feedback} from '../../../api/models/feedback';

@Component({
  selector: 'app-course-reviews',
  templateUrl: './course-reviews.component.html',
  styleUrls: ['./course-reviews.component.css']
})
export class CourseReviewsComponent implements OnInit {

  @Input() feedbacks: Feedback[] | undefined;

  constructor() {
  }

  ngOnInit(): void {
  }

}
