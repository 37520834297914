<div [routerLink]="['/course', course.slug]" class="course bg-white h-100 align-self-stretch">
  <figure class="m-0">
    <img src="{{course.featured_image}}" alt="Image" class="img-fluid">
  </figure>
  <div class="course-inner-text py-4 px-4">
    <span class="course-price">FREE</span>
    <div class="meta">{{course.lessons_count}} Lessons</div>
    <h3><a href="/course/{{course.slug}}">{{course.title}}</a></h3>
    <p class="course-desc" [innerHTML]="course.short_desc"></p>
  </div>
  <div class="d-flex border-top stats">
    <div class="py-3 px-4"><span class="fa fa-user"></span>&nbsp;{{course.author_name}}</div>
    <div class="py-3 px-4 w-45 ml-auto border-left"><span class="fa fa-check"></span>&nbsp;{{course.category_name}}
    </div>
  </div>
</div>
