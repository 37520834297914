<div class="card" style="height: 200px; margin: 16px 0;">
  <div class="card-body">
    <div class="row">
      <div class="col-sm-3">
        <img src="../../../../../assets/images/ic_default_profile_pic.png" alt="user pic"
             title="{{feedback?.user_name}}"/>
      </div>
      <div class="col-sm-9">
        <h5 style="margin-top: 5px;">{{feedback?.user_name}}</h5>
        <p class="small" *ngIf="feedback != undefined">
          <span style="margin-right: 8px;"><i style="margin-right: 2px;"
                                              [ngClass]="i <= feedback!!.rating ? 'fa fa-star' : 'fa fa-star-o'"
                                              *ngFor="let i of [1,2,3,4,5]"></i></span>
          {{ feedback?.updated_at | dateAgo}}
        </p>
        <p class="feedback">{{ feedback?.feedback }}</p>
      </div>
    </div>
  </div>
</div>
