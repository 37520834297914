<div class="site-section courses-title" id="courses-section">
  <div class="container">
    <div class="row mb-5 justify-content-center">
      <div class="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="">
        <h2 class="section-title">Courses</h2>
      </div>
    </div>
  </div>
</div>

<div class="site-section courses-entry-wrap" data-aos="fade-up" data-aos-delay="100">
  <div class="container">
    <div class="row">
      <div *ngFor="let course of courses" class="col-md-4" style="margin-bottom: 24px;">
        <app-course-card [course]="course"></app-course-card>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-7 text-center" style="margin-top: 32px">
        <a href="/all-courses" class="customPrevBtn btn btn-primary m-1">View All Courses</a>
      </div>
    </div>
  </div>
</div>

