<div class="site-wrap">

  <app-header></app-header>

  <app-intro></app-intro>

  <app-courses></app-courses>

  <app-aboutme></app-aboutme>

  <app-contact></app-contact>

  <app-footer></app-footer>

</div>
