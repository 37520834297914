import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Lesson} from '../../../api/models/lesson';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

const youtubeUrl = 'https://www.youtube.com/embed/';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnChanges {

  @Input() lessonCount = 1;
  @Input() lesson: Lesson;
  index = 1;
  videoUrl: SafeResourceUrl;

  constructor(private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer) {
    this.activatedRoute.paramMap.subscribe(params => {
      this.index = params.get('index') as unknown as number;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(youtubeUrl + this.getId(this.lesson?.video_url));
    console.log('VideoUrl: ' + this.videoUrl);
  }

  getId(url: string | null): string | null {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);
    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }
}
