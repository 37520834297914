import {Component, Input, OnInit} from '@angular/core';
import {Lesson} from '../../../api/models/lesson';

@Component({
  selector: 'app-course-content',
  templateUrl: './course-content.component.html',
  styleUrls: ['./course-content.component.css']
})
export class CourseContentComponent implements OnInit {

  @Input() lessons: Lesson[] | undefined;

  constructor() {
  }

  ngOnInit(): void {
  }

}
