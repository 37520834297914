<div class="site-section" id="teachers-section">
  <div class="container">

    <div class="row mb-5 justify-content-center">
      <div class="col-lg-7 mb-5 text-center" data-aos="fade-up" data-aos-delay="">
        <h2 class="section-title">About Me</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-6 mb-4" data-aos="fade-up" data-aos-delay="100" style="margin: 0 auto;">
        <div class="teacher text-center">
          <img src="../../../../assets/images/belal-khan.jpg" alt="Image" class="img-fluid w-50 rounded-circle mx-auto mb-4">
          <div class="py-2">
            <h3 class="text-black">Belal Khan</h3>
            <p class="position">Google Developers Expert</p>
            <p>I am a professional Android Developer having more than 5 years of experience in building Android
              Apps.</p>
            <p>Currently living in Bangalore, India and working as a Senior Android Developer.</p>
            <p><a href="https://www.simplifiedcoding.net/about/" target="_blank">Click here</a> to know more about me.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
