<app-header></app-header>
<div class="container-fluid" style="background-color: #3e3e3e">
  <div class="container" style="padding-top: 25px; padding-bottom: 64px;">
    <div class="row">
      <div class="col-md-12">
      </div>
    </div>
  </div>
</div>
<div class="container" style="padding:12px 21px; margin-top: 24px;">
  <div class="row">
    <div class="col-md-7" style="text-align: justify;">
      <h2 class="text-dark">Welcome Friend!</h2>
      <p class="text-danger">So you are finally here to get the source code of the project.
        But it is still <b>highly recommended</b> that first you try yourself by following the tutorial.
        And try looking for my source only when you already <b>Gave Up Trying</b>.
      </p>

      <h3 class="text-dark">Download Instructions:</h3>
      <ol>
        <li>Make sure you are Logged In; Now click <b>Subscribe Button</b> on the right. <b><i>(Even if you are already
          subscribed)</i></b></li>
        <li>Make sure you <b>don't close</b> this page</li>
        <li>Clicking on the subscribe button will take you to <b><a
          href="https://www.youtube.com/SimplifiedCoding?sub_confirmation=1" target="_blank">Simplified Coding's</a></b>
          YouTube Channel
        </li>
        <li>Now please <b>Subscribe to Simplified Coding</b>. One subscribe at least this is the price you can easily
          pay for the code.
        </li>
        <li>Once you have subscribed; close youtube page and come back to this page <b>(Make sure you don't close or
          refresh this page)</b>.
        </li>
        <li>Finally, in this page wait for few seconds and your download link will unlock.</li>
      </ol>

    </div>
    <span *ngIf="authService.currentUser != null; then downloadCard else loginFirst"></span>
    <ng-template #downloadCard>
      <div class="col-md-5">

        <div class="card">
          <div class="card-header">
            <label class="text-dark"><b>Project Name:</b><br/><span id="project-name"
                                                                    class="text-primary h5">{{ download.name }}</span></label>
          </div>
          <div class="card-body">
            <div class="text-center" *ngIf="loading">
              <div id="loading"></div>
              <p>Please wait.. <br/>Download links are being unlocked...</p>
            </div>
            <div class="d-flex justify-content-center">
              <table class="center" id="table-download" [hidden]="!showDownloadLinks">
                <tbody>
                <tr>
                  <td class="text-dark">Direct Link</td>
                  <td>
                    <a href="{{directCodeLink}}" id="direct-link">Download Zip Directly</a>
                  </td>
                </tr>
                <tr>
                  <td class="text-dark">Git Repository &nbsp;</td>
                  <td>
                    <span *ngIf="repoLink != null">
                    <a href="{{repoLink}}" target="_blank" id="repo-link">
                    Go to The Code Repository
                    </a>
                    </span>

                    <span *ngIf="repoLink == null">
                      Repository Not Available...
                    </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex justify-content-center" *ngIf="!showDownloadLinks && !loading">
              <button class="btn btn-lg btn-secondary" id="download-locked" disabled style="width: 100%;">
                <span class="h3"><i class="fa fa-lock"></i> Download Links are <br/><b>LOCKED</b></span>
              </button>
            </div>

            <p *ngIf="!loading && !showDownloadLinks" class="text-center text-muted"
               style="line-height: 1.3 !important; margin-top: 54px;" id="hint-text">
              Click on the button below, and<br/><b>Subscribe to Simplified Coding</b><br/>to <b>TO UNLOCK</b> download
              links.
            </p>

            <div class="d-flex justify-content-center">
              <p class="display-3">👇</p>
            </div>

            <div class="d-flex justify-content-center">
              <a (click)="setSubscribeClicked(true)"
                 href="https://www.youtube.com/c/SimplifiedcodingNetOfficial?sub_confirmation=1" target="_blank"
                 class="btn btn-lg btn-danger h3" style="width: 100%; height:auto;">
                <span class="h3"><i class="fab fa-youtube"></i> Subscribe to <br/><b>Simplified Coding</b></span>
              </a>
            </div>
          </div>
        </div>

      </div>
    </ng-template>
    <ng-template #loginFirst>
      <div class="col-md-5">
        <div class="card card-locked">
          <div class="card-body">
            <div class="card-content">
              <div class="text-center">
                <i class="fa fa-4x fa-lock"></i><br/><br/>
                <div class="text-dark h2">Login to Download</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<app-footer></app-footer>

