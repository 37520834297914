<app-header></app-header>
<div class="container-fluid" style="background-color: #3e3e3e">
  <div class="container" style="padding-top: 120px; padding-bottom: 64px;">
    <div class="row">
      <div class="col-md-12">
        <div class="text-center">
          <h1>About</h1>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container" style="margin-top: 64px;">
  <div class="row">
    <div class="col-lg-7 mb-5" data-aos="fade-up" data-aos-delay="100">
      <img src="assets/images/Belal-Khan-GDE.jpeg" alt="Image" class="img-fluid">
    </div>
    <div class="col-lg-5 ml-auto" data-aos="fade-up" data-aos-delay="200">
      <h2 class="text-black mb-4">Welcome to Simplified Coding</h2>
      <p class="mb-4">Hi! my name is <b>Belal Khan</b>. And I am a <a
        href="https://developers.google.com/community/experts/directory/profile/profile-belal_haque" target="_blank">Google
        Developer's Expert</a>.</p>
      <p class="mb-4">
        I guess you already know about <a href="https://www.simplifiedcoding.net">Simplified Coding</a>.
        It all started with a Blog, that I created when I was pursuing my Graduation.
        As I just started to learning about tech that time; I thought it is a good idea to make a blog and
        share my learnings with everyone on the internet.
      </p>
      <p>
        But now you are at SimplifiedCoding.in; I've created this Web Application using Angular, to publish
        all the courses tha I've created so far. And yes everything is Absolutely <b>FREE</b> here.
      </p>

      <p>You can learn more about me <a href="https://www.simplifiedcoding.net/about/" target="_blank">here</a></p>
    </div>
  </div>
</div>
<app-footer></app-footer>
