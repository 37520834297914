import {Component, Injector, OnInit} from '@angular/core';
import {BaseApiComponent} from '../../api/base.api.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CourseDetails} from '../../api/models/course-details';
import {AuthService} from "../../services/auth.service";
import {Download} from "../../api/models/download";

@Component({
  selector: 'app-start-course',
  templateUrl: './start-course.component.html',
  styleUrls: ['./start-course.component.css']
})
export class StartCourseComponent extends BaseApiComponent implements OnInit {

  index = 1;
  courseSlug: string | null = '';
  courseDetailsAndLessons: CourseDetails;
  downloads: Download[] | undefined;

  constructor(private injector: Injector, private router: ActivatedRoute, private route: Router, public authService: AuthService) {
    super(injector);
  }

  ngOnInit(): void {
    this.router.paramMap.subscribe(params => {
      this.index = params.get('index') as unknown as number;
      this.courseSlug = params.get('slug');
      this.getCourseDetails();
    });
  }

  getCourseDetails(): void {
    this.apiHttpService
      .get(this.apiEndpointService.getCourseLessons(this.courseSlug))
      .subscribe((result) => {
        this.courseDetailsAndLessons = result;
        this.getLessonDownloads(this.courseDetailsAndLessons.lessons[this.index - 1].id);
        this.navigateIfInvalidUrl();
      });
  }

  getLessonDownloads(lessonId: number): void {
    this.apiHttpService
      .get(this.apiEndpointService.getLessonDownloads(lessonId))
      .subscribe((result) => {
        this.downloads = result;
      });
  }

  goToDownload(downloadId: string): void {
    this.route.navigateByUrl('/download/' + downloadId);
  }

  navigateIfInvalidUrl(): void {
    if (+this.index < 1 || +this.index > +this.courseDetailsAndLessons.lessons.length) {
      this.route.navigateByUrl('/');
    }
  }
}
