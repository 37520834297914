import {Component, Injector, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {BaseApiComponent} from '../../../api/base.api.component';
import firebase from 'firebase';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent extends BaseApiComponent implements OnInit {

  subject = '';
  message = '';

  success = false;
  result: string;

  constructor(private injector: Injector, public authService: AuthService) {
    super(injector);
  }

  ngOnInit(): void {
  }

  sendMessage(n: string | null | undefined, e: string | null | undefined): void {
    this.apiHttpService.post(
      this.apiEndpointService.getSendMessageEndpoint(), {
        name: n,
        email: e,
        subject: this.subject,
        message: this.message,
      }
    ).subscribe((result) => {
      this.success = true;
      this.result = result.message;
      this.subject = '';
      this.message = '';
    });
  }
}
