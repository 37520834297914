<div class="container-fluid" style="background-color: #3e3e3e">
  <div class="container" style="padding-top: 180px; padding-bottom: 64px;">
    <div class="row">
      <div class="col-md-7" style="margin-bottom: 20px">
        <h1>{{course?.title}}</h1>
        <p [innerHTML]="course?.short_desc"></p>
        <p>Created By: {{course?.author_name}}<br/>
          Last Updated: {{course?.updated_at | dateAgo}}</p>
        <button class="btn btn-outline-primary"><i class="fa fa-star"></i>&nbsp;Bookmark</button>&nbsp;
        <button class="btn btn-outline-primary"><i class="fa fa-share"></i>&nbsp;Share</button>
      </div>
      <div class="col-md-5">
        <div class="card" style="width: 100%;">
          <img src="{{course?.featured_image}}" class="card-img-top">
          <div class="card-body">
            <div class="text-center">
              <button [routerLink]="['start', 1]" class="btn btn-primary" style="width: 100%;">
                Start Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
