import {Component, Input, OnInit} from '@angular/core';
import {Lesson} from '../../../api/models/lesson';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  @Input() lessons: Lesson[] | undefined;
  index: number;

  constructor(private router: ActivatedRoute) {
    console.log('X1 ' + this.lessons);
  }

  ngOnInit(): void {
    this.router.paramMap.subscribe(params => {
      this.index = params.get('index') as unknown as number;
    });
  }

}
