import {Component, Input, OnInit} from '@angular/core';
import {Feedback} from '../../../../api/models/feedback';

@Component({
  selector: 'app-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.css']
})
export class ReviewCardComponent implements OnInit {

  @Input() feedback: Feedback | undefined;

  constructor() {
  }

  ngOnInit(): void {
  }

}
