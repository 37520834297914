import {Component, Injector, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {BaseApiComponent} from '../../api/base.api.component';
import {ActivatedRoute} from '@angular/router';
import {Download} from '../../api/models/download';
import firebase from 'firebase';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent extends BaseApiComponent implements OnInit {

  currentUser: firebase.User | null;
  showDownloadLinks = false;
  downloadId: number;
  download: Download;
  loading = false;
  timer: any;

  directCodeLink = '';
  repoLink: string | null = null;

  constructor(private injector: Injector, public authService: AuthService, private activatedRoute: ActivatedRoute) {
    super(injector);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.downloadId = params.get('id') as unknown as number;
      this.getDownload();
    });

    this.authService.afAuth.authState.subscribe(user => {
      this.currentUser = user;
    });
    this.unlockDownloadLink();
  }

  unlockDownloadLink(): void {
    const flag: boolean = localStorage.getItem('subscribe_button_clicked') === 'true';
    if (flag) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.showDownloadLinks = true;
      }, 8000);
    }
  }

  getDownload(): void {
    this.apiHttpService
      .get(this.apiEndpointService.getDownloadEndpoint(this.downloadId))
      .subscribe((result) => {
        this.download = result;
        if (this.download.url.includes('github.com')) {
          this.directCodeLink = this.download.url + '/zipball/master/';
          this.repoLink = this.download.url;
        }
      });
  }

  setSubscribeClicked(clicked: boolean): void {
    localStorage.setItem('subscribe_button_clicked', String(clicked));
    if (clicked) {
      this.unlockDownloadLink();
    }
  }
}
