<div class="container" style="margin-top: 32px;">
  <h2>What other says about this course</h2>
  <div class="row">
    <div class="col-sm-4" *ngFor="let feedback of feedbacks">
      <app-review-card [feedback]="feedback"></app-review-card>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="text-center">
        <button class="btn btn-primary" style="margin-top: 20px;">See all reviews</button>
      </div>
    </div>
  </div>
</div>
