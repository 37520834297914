<app-header></app-header>
<div class="container-fluid" style="background-color: #3e3e3e">
  <div class="container" style="padding-top: 120px; padding-bottom: 64px;">
    <div class="row">
      <div class="col-md-12">
        <div class="text-center">
          <h1>All Courses</h1>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container" style="margin-top: 64px;">
  <div class="row">
    <div *ngFor="let course of courses" class="col-md-4" style="margin-bottom: 24px;">
      <app-course-card [course]="course"></app-course-card>
    </div>
  </div>
</div>
<app-footer></app-footer>
