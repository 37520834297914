import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Pipe({name: 'safe'})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url: string | undefined): SafeResourceUrl {
    if (url === undefined) {
      url = 'https://www.youtube.com/embed/78RHCvTQnE0';
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
