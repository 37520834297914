import {Component, Input, OnInit} from '@angular/core';
import {CourseDetails} from '../../../api/models/course-details';

@Component({
  selector: 'app-course-desc',
  templateUrl: './course-desc.component.html',
  styleUrls: ['./course-desc.component.css']
})
export class CourseDescComponent implements OnInit {

  @Input() content: string | undefined;

  constructor() {
  }

  ngOnInit(): void {
  }

}
