import {Component, Input, OnInit} from '@angular/core';
import {Course} from '../../../api/models/course';

@Component({
  selector: 'app-course-intro',
  templateUrl: './course-intro.component.html',
  styleUrls: ['./course-intro.component.css']
})
export class CourseIntroComponent implements OnInit {

  @Input() course: Course | undefined;

  constructor() {
  }

  ngOnInit(): void {
  }

}
