<div class="site-section bg-light" id="contact-section">
  <div class="container">

    <div class="row justify-content-center">
      <div class="col-md-7">


        <h2 class="section-title mb-3">Message Us</h2>
        <p class="mb-5">For any question, query or feedback, feel free to message us.</p>

        <div class="form-group row">
          <div class="col-md-12">
            <input type="text" class="form-control" placeholder="Name" readonly
                   [value]="authService?.currentUser?.displayName">
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-12">
            <input type="email" class="form-control" placeholder="Email" readonly
                   [value]="authService?.currentUser?.email">
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-12">
            <input type="text" class="form-control" placeholder="Subject" [(ngModel)]="subject">
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-12">
                <textarea class="form-control" id="" cols="30" rows="10"
                          placeholder="Write your message here." [(ngModel)]="message"></textarea>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-7">

            <input (click)="sendMessage(authService.currentUser?.displayName, authService.currentUser?.email)"
                   [disabled]="authService.currentUser == null"
                   class="btn btn-primary py-3 px-5 btn-block btn-pill" value="Send Message"><br/>
            <span *ngIf="authService.currentUser == null">You must be logged in to send message</span>
          </div>
        </div>

        <div class="form-group row" *ngIf="success">
          <div class="col-md-12">
            <div class="alert alert-info"><label class="text-info">{{result}}</label></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
