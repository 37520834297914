import {Component, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {BaseApiComponent} from '../../../api/base.api.component';
import {Course} from '../../../api/models/course';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent extends BaseApiComponent implements OnInit {

  courses: Course[];

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.apiHttpService
      .get(this.apiEndpointService.getLatestCoursesEndpoint())
      .subscribe((result) => {
        this.courses = result.courses;
        console.log(this.courses);
      });
  }

}
