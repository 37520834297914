import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import firebase from 'firebase';
import auth = firebase.auth;
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import {ApiHttpService} from '../api/core/services/api-http.service';
import {ApiEndpointsService} from '../api/core/services/api-endpoint.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  currentUser: firebase.User | null;

  constructor(
    public afAuth: AngularFireAuth,
    private apiHttpService: ApiHttpService,
    private apiEndpointService: ApiEndpointsService
  ) {
    afAuth.authState.subscribe(user => {
      this.currentUser = user;
      if (this.currentUser != null && localStorage.getItem('user_signed_up') !== 'true') {
        this.SignupUser();
      }
    });
  }

  GoogleAuth(): Promise<void> {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  AuthLogin(provider: GoogleAuthProvider): Promise<void> {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        console.log('You have been successfully logged in!');
      }).catch((error) => {
        console.log(error);
      });
  }

  Logout(): void {
    this.afAuth.signOut();
  }

  SignupUser(): void {
    this.apiHttpService
      .post(this.apiEndpointService.getSignupEndpoint(), {
        name: this.currentUser?.displayName,
        email: this.currentUser?.email,
        profile_pic: this.currentUser?.photoURL
      }).subscribe((result) => {
      localStorage.setItem('user_signed_up', 'true');
    });
  }
}
