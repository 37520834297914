<style>
  iframe {
    width: 100% !important;
    height: 100% !important;
  }
</style>
<div style="width: 100%; height: 430px;">
  <iframe width="560" height="315" [src]="videoUrl" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
  <!--  <youtube-player-->
  <!--    videoId="{{lesson?.video_url}}"></youtube-player>-->
</div>
<div class="col-md-12" style="margin-top: 12px;">
  <div class="text-center">
    <button [disabled]="+index <= 1" type="button" class="btn btn-outline-primary" [routerLink]="['../', +index-1]"><i
      class="fa fa-arrow-left"></i>&nbsp;Prev
    </button>&nbsp;
    <button [disabled]="+index >= +lessonCount" type="button" class="btn btn-outline-primary"
            [routerLink]="['../', +index+1]">Next&nbsp; <i
      class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>

