<form [formGroup]="subscriptionForm" (ngSubmit)="onSubmit()" class="form-box">
  <h3 class="h4 text-black mb-4">Subscribe to our Newsletter</h3>
  <p class="text-black mb-4">You will never miss our podcast, latest news etc.</p>
  <div class="form-group">
    <input required formControlName="email" type="text" class="form-control" placeholder="Email Address">
  </div>
  <div *ngIf="subscribed" class="form-group">
    <label class="alert alert-success text-dark">Congratulations! You've successfully subscribed to our mailing
      list.</label>
  </div>
  <div class="form-group">
    <input type="submit" class="btn btn-primary btn-pill" value="Subscribe Now">
  </div>
</form>
