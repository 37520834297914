import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './ui/home/home.component';
import {CourseDetailsComponent} from './ui/course-details/course-details.component';
import {StartCourseComponent} from './ui/start-course/start-course.component';
import {AllCoursesComponent} from './ui/all-courses/all-courses.component';
import {AboutComponent} from './ui/about/about.component';
import {DownloadComponent} from './ui/download/download.component';
import {PrivacyPolicyComponent} from "./ui/privacy-policy/privacy-policy.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'course/:slug', component: CourseDetailsComponent},
  {path: 'course/:slug/start/:index', component: StartCourseComponent},
  {path: 'all-courses', component: AllCoursesComponent},
  {path: 'about', component: AboutComponent},
  {path: 'download/:id', component: DownloadComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
