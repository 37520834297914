import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-start-course-intro',
  templateUrl: './start-course-intro.component.html',
  styleUrls: ['./start-course-intro.component.css']
})
export class StartCourseIntroComponent implements OnInit {

  @Input() courseTitle: string | undefined;

  constructor() {
  }

  ngOnInit(): void {
  }

}
