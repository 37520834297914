<div class="site-wrap">

  <app-header></app-header>

  <app-start-course-intro [courseTitle]="courseDetailsAndLessons?.course?.title"></app-start-course-intro>

  <div class="container">
    <div class="row">
      <div class="col-md-8" *ngIf="courseDetailsAndLessons && courseDetailsAndLessons!.lessons!.length > 0">
        <h2 class="h3">{{ courseDetailsAndLessons!.lessons[index - 1]!.title}}</h2>
        <app-video-player [lesson]="courseDetailsAndLessons!.lessons[index -1]"
                          [lessonCount]="courseDetailsAndLessons?.lessons?.length || 1"></app-video-player>
      </div>
      <div class="col-md-4">
        <h2 class="h3">Course content</h2>
        <div class="index">
          <app-index [lessons]="courseDetailsAndLessons?.lessons"></app-index>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <h3 class="h4">Description</h3>
        <div *ngIf="courseDetailsAndLessons !== undefined">
          <p [innerHTML]="courseDetailsAndLessons!.lessons[index -1]!.content"></p>

          <div *ngIf="downloads !== undefined">
            <h3 class="h4">Downloads</h3>
            <p>Do you want the source code? You can get it from here:</p>
            <div *ngFor="let download of downloads">
              <button style="margin-top: 12px;" (click)="goToDownload(download.id)" class="btn btn-primary"
                      [disabled]="authService.currentUser == null">
                Download Code of {{download.name}}
              </button>
            </div>
            <p *ngIf="authService.currentUser == null">You must <span class="text-info"
                                                                      (click)="authService.GoogleAuth()">Login</span>
              to Download Source Code</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="ad-space text-center">
          <p>Your Ad Here</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-course-suggestions [suggestions]="courseDetailsAndLessons?.recommended"></app-course-suggestions>
    </div>
  </div>

  <app-footer></app-footer>

</div>
