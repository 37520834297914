import {Component, Input, OnInit} from '@angular/core';
import {Course} from '../../../../api/models/course';
import {Router} from '@angular/router';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.css']
})
export class CourseCardComponent implements OnInit {

  // @ts-ignore
  @Input() course: Course;


  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }
}
