import {Component, Input, OnInit} from '@angular/core';
import {Course} from '../../../api/models/course';

@Component({
  selector: 'app-course-suggestions',
  templateUrl: './course-suggestions.component.html',
  styleUrls: ['./course-suggestions.component.css']
})
export class CourseSuggestionsComponent implements OnInit {

  @Input() suggestions: Course[] | undefined;

  constructor() {
  }

  ngOnInit(): void {
  }

}
