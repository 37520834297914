import {Injectable} from '@angular/core';
import {UrlBuilder} from '../../shared/url-builder';
import {QueryStringParameters} from '../../shared/query-string-parameters';
import {Constants} from '../../config/constants';

@Injectable()
export class ApiEndpointsService {
  constructor(
    private constants: Constants
  ) {
  }

  private createUrl(
    action: string
  ): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(this.constants.BASE_URL, action);
    return urlBuilder.toString();
  }

  private createUrlWithQueryParameters(
    action: string,
    queryStringHandler?:
      (queryStringParameters: QueryStringParameters) => void
  ): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(
      this.constants.BASE_URL,
      action
    );
    if (queryStringHandler) {
      queryStringHandler(urlBuilder.queryString);
    }
    return urlBuilder.toString();
  }

  private createUrlWithPathVariables(
    action: string,
    pathVariables: any[] = []
  ): string {
    let encodedPathVariablesUrl = '';
    for (const pathVariable of pathVariables) {
      if (pathVariable !== null) {
        encodedPathVariablesUrl +=
          `/${encodeURIComponent(pathVariable.toString())}`;
      }
    }
    const urlBuilder: UrlBuilder = new UrlBuilder(
      this.constants.BASE_URL,
      `${action}${encodedPathVariablesUrl}`
    );
    return urlBuilder.toString();
  }

  public getSubscribeToNewsletterEndpoint(): string {
    return this.createUrl('subscribe');
  }

  public getLatestCoursesEndpoint(): string {
    return this.createUrl('course/latest');
  }

  public getAllCoursesEndpoint(): string {
    return this.createUrl('course/all');
  }

  public getCourseDetails(slug: string | null): string {
    return this.createUrl('course/details/' + slug);
  }

  public getCourseLessons(slug: string | null): string {
    return this.createUrl('course/lessons/' + slug);
  }

  public getSignupEndpoint(): string {
    return this.createUrl('auth/signup');
  }

  public getDownloadEndpoint(downloadId: number): string {
    return this.createUrl('download/' + downloadId);
  }

  public getLessonDownloads(lessonId: number): string {
    return this.createUrl('course/downloads/' + lessonId);
  }

  public getSendMessageEndpoint(): string {
    return this.createUrl('sendmessage');
  }
}
