<footer class="footer-section bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h3>About Simplified Coding</h3>
        <p>Simplified Coding is a platform for all the tech enthusiasts, who love to keep learning tech.
          We basically focus on producing free quality courses for everyone.
          Currently our focus is at Android Application Development. And you will find all the information related to
          Android Application Development here.<br/>
          <a href="https://www.simplifiedcoding.net/about/" target="_blank">Click Here</a> if you want to know the man
          behind Simplified Coding.</p>
      </div>

      <div class="col-md-3 ml-auto">
        <h3>Links</h3>
        <ul class="list-unstyled footer-links">
          <li><a [routerLink]="['/']">Home</a></li>
          <li><a [routerLink]="['/about']">About</a></li>
          <li><a [routerLink]="['/all-courses']">Courses</a></li>
          <li><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
        </ul>
      </div>

      <div class="col-md-4">
        <h3>Social Links</h3>
        <p>Follow us in our Social Media Profiles.</p>
        <div class="social-links">
          <a href=""><i class="fa fa-2x fa-youtube-play"></i></a>
          <a href=""><i class="fa fa-2x fa-instagram"></i></a>
          <a href=""><i class="fa fa-2x fa-facebook-official"></i></a>
          <a href=""> <i class="fa fa-2x fa-twitter"></i></a>
        </div>
      </div>
    </div>

    <div class="row pt-5 mt-5 text-center">
      <div class="col-md-12">
        <div class="border-top pt-5">
          <p>
            Copyright &copy;2021 | All rights are reserved
          </p>
        </div>
      </div>

    </div>
  </div>
</footer>
